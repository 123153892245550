import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Header from "../components/Header"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "cards/cardimg.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO pageTitle="About me" />
      <Header pageTitle="About me" bgImage="backgrounds/header.jpg" />
      <section className="intro-text grey-bg">
        <div className="container">
          <p>So, in order of complete transparency, beauty hasn’t always been my thing!</p>
          <p>Like many girls, I didn’t have a clue what I wanted to do leaving school. I remember closing my eyes, pointing my finger at the college prospectus and landing upon a European secretarial course. Admin became my career. It was deeply unsatisfying but paid the bills, and I liked the people I worked with.</p>
          <p>But in 2012, everything changed.</p>
        </div>
      </section>
      <section className="body-text">
        <div className="container">
          <Img className="floated-image framed-image" fluid={data.file.childImageSharp.fluid} alt="" />
          <p>Life has a funny old way of throwing us curve balls doesn’t it?</p>
          <p>In 2012, I was thrown a biggie. I heard those words no one wants to hear… "you have cancer".</p>
          <p>If I’m brutally honest, one of my first thoughts was… "great, I get some time off work". If that doesn’t scream <em>you need a change</em>, I don’t know what does.</p>
          <p>I know for many women one of their first concerns is the fear of losing their hair. Me, I wasn’t worried at all. If anything, I was excited to see what I looked like.</p>
          <p>On the flip side, I didn’t expect the misery and sadness I felt losing my brows and lashes - I mean, I grew up in the 90s when plucking your brows into oblivion was the norm!</p>
          <p>The shocking realisation dawned that brows matter - a lot - not just for the structure and overall look of our face, but for our internal identity, self-confidence, and esteem.</p>
          <p>I would look in the mirror and not recognise myself. Spend hours of steroid-fuelled sleepless nights researching how to create brows. Never daring to book an appointment at a salon in fear of feeling stupid and embarrassed.</p>
          <p>Hundreds of hours of researching later an obsession was born, and a new trajectory set.</p>
          <p>At the end of 2014 I completed my last major surgery and at the beginning of 2015 I was offered redundancy.</p>
          <p>I just knew this was a rare opportunity for a fresh start. I knew immediately I would launch my new career specialising in brows, and possibly grow from there.</p>
          <p>And Birds of Beauty Parlour was born.</p>
          <p>I had a dream of crafting a space clients felt safe and not embarrassed to visit, like I once had, because they knew I got them, understood them, and would do my utmost to give them the results they desired.</p>
          <p>To this day, I continue with that dream.</p>
          <p>I believe it’s key that clients walk away happier than when they walk in.</p>
          <p>I believe it doesn’t have to be scary to visit a salon.</p>
          <p>I believe we all deserve to feel happy in our own skin.</p>
          <p>My salon isn’t for everyone, and that’s okay. For one I don’t offer many treatments, I specialise in a few, and for some the buzz of a bright busy salon is their thing, and that’s great too.</p>
          <p>But if you think we are the right fit for each other – why not head over to my <Link to="/treatments">treatments menu</Link> to find out more about what I offer.</p>
          <p><strong>Here's to a happier you.</strong></p>
        </div>
      </section>
    </Layout>
  )
}